import React from 'react';
import './index.css';
export default function LoadingSpinner({ message = 'Progressing' }) {
  return (
    <div className="w-fit mx-auto relative top-0 h-12 bg-black/50 flex items-center px-4 border-2 border-gray-400 backdrop-blur-lg rounded-md">
      <div className="loadingio-spinner-spinner-977el9wwy2v">
        <div className="ldio-4j5ay0xf86g">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <p className="text-gray-300 text-base font-semibold">{message}</p>
    </div>
  );
}
