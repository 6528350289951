import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';

export default function Success({ message }) {
  return (
    <div className="w-fit mx-auto relative top-0 py-2 bg-black/50 flex items-center px-4 border-2 border-gray-400 backdrop-blur-lg rounded-md gap-2">
      <HiCheckCircle className="w-8 h-8 text-[#01B969]" />
      <p className="text-gray-300 text-base font-semibold">{message}</p>
    </div>
  );
}
