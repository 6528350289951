import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Filter({
  name,
  filterOptions,
  filterBy,
  selectedFilter,
  onFilterChange,
}) {
  const navigate = useNavigate();

  function handleOnChange(e) {
    const newFilter = e.target.value;

    if (!newFilter) {
      return navigate('');
    }

    navigate(`?${filterBy}=${newFilter.replace(/\s/g, '').toLowerCase()}`);

    // Call the onFilterChange callback to update the selected filter in the parent component
    onFilterChange(newFilter);
  }

  // function handleOnChange(e) {
  //   if (!e.target.value) {
  //     return navigate('');
  //   }

  //   navigate(`?${filterBy}=${e.target.value.replace(/\s/g, '').toLowerCase()}`);
  // }

  return (
    <div>
      <div>
        <label htmlFor="states" className="sr-only">
          Filter
        </label>
        <select
          id="states"
          className="block w-full rounded-lg border border-neutral-300 bg-[#FFFFFF] dark:bg-[#212B36] p-2.5 text-sm text-neutral-800 dark:border-neutral-300 dark:text-neutral-300 dark:placeholder-neutral-400"
          onChange={handleOnChange}
          value={selectedFilter}
        >
          {filterOptions.map((plan) => (
            <option key={plan} value={plan}>
              {plan}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
