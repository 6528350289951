import React from 'react';
import ReactTimeAgo from 'react-time-ago';
// import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { Avatar, Stack } from '@mui/material';

export default function NotificationItem({
  _id,
  user,
  title,
  createdAt,
  readAt,
}) {
  const { notificationId } = useParams();
  return (
    <div
      className={`flex items-start gap-2 w-full p-4 my-2 rounded ${
        !readAt
          ? 'bg-[#cacaca] dark:bg-[#10151a]'
          : 'bg-[#FFFFFF] dark:bg-[#212B36]'
      } ${
        notificationId === _id
          ? 'border border-neutral-500'
          : 'border border-transparent'
      }`}
      whileHover={{
        y: -3,
      }}
    >
      <Stack>
        <Avatar
          alt={user.fullName}
          src={user?.profileImage}
          sx={{ width: '2.5rem', height: '2.5rem', objectFit: 'cover' }}
        />
      </Stack>

      <div>
        <h4 className="text-sm text-brand-heading line-clamp-2 h-10 overflow-hidden">
          <span className="font-semibold">{user.fullName}</span> {' ' + title}
        </h4>

        <span className="text-xs text-neutral-800 dark:text-neutral-300">
          <ReactTimeAgo
            date={new Date(createdAt)}
            locale="en-US"
            timeStyle="round-minute"
          />
        </span>
      </div>
    </div>
  );
}
