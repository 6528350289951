import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BsBank2 } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import authServices from '../../services/auth.service';

export default function BankInfoCard({ bankInfo }) {
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('bankInfos updated:', bankInfo);
  }, [bankInfo]);

  const handleDelete = async () => {
    try {
      const response = await dispatch(
        authServices.deleteBankInfo(bankInfo._id)
      );
      if (response.success) {
        toast.success(response.status);
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.success(error.message || status);
    }
  };

  return (
    <div className="withdrawal_card mt-2 md:col-span-3 p-6 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md overflow-hidden">
      <div className="flex flex-col items-center space-y-4">
        <BsBank2 className="text-5xl" />
        <h3>{bankInfo.beneficiaryBank?.bankName}</h3>
        <p>Account Holder: {bankInfo.beneficiaryBank?.payee}</p>
        <p>Account Number: {bankInfo.beneficiaryBank?.accountNumber}</p>
        <p>Routing Number: {bankInfo.beneficiaryBank?.routingCode}</p>
      </div>
      <div className="overlay absolute top-1/2 left-0 right-0 bottom-0 p-6 bg-[#DFE3E8] dark:bg-[#161C24] shadow-lg flex justify-center items-center space-x-4">
        {/* <button className="px-6 py-1 text-sm bg-[#5cb85c] border-[1px] rounded-sm">
            Edit
          </button> */}
        <button
          onClick={handleDelete}
          className="px-6 py-1 text-sm bg-[#d9534f] border-[1px] rounded-sm"
        >
          Remove Bank Info
        </button>
      </div>
    </div>
  );
}
