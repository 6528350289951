import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { motion } from 'framer-motion';

import { useSelector, useDispatch } from 'react-redux';
import withdrawlHistoryService from '../../services/withdraw.service';

export default function Details({ user }) {
  const dispatch = useDispatch();
  const withdrawl = useSelector((state) => state.withdrawal);
  // const [data, setData] = useState({}); // Initialize with the data from Redux

  useEffect(() => {
    (async () => {
      try {
        const response = await dispatch(
          withdrawlHistoryService.getTotalUserWithdrawal(user._id)
        );
        // console.log(response.data);
        // if (response.success) {
        //   setData(response.data);
        // }

        // if (!response.success) {
        //   console.log(response.error.message);
        // }
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error || status);
      }
    })();
  }, [dispatch]);

  // useEffect(() => {
  //   console.log(withdrawl.userTotalWithdraw);
  // }, [withdrawl.userTotalWithdraw]);

  if (!user || user === null) return;
  return (
    <div className="my-4 mt-2 grid grid-cols-1 gap-4 ">
      <div className="overflow-hidden rounded-xl bg-[#FFFFFF] dark:bg-[#313b49] shadow-lg">
        <div className="bg-brand-white dark:bg-brand-dark-secondary flex flex-col gap-8 px-6 py-4 ">
          <div className="ml-3">
            <h6 className="text-xs font-medium text-[#565758] dark:text-[#bdbdbd] md:text-sm">
              Name
            </h6>
            <p className="text-lg font-bold dark:text-[#bdbdbd]">
              {user.firstName + ' ' + user.lastName}
            </p>
          </div>
          <div className="ml-3">
            <h6 className="text-xs font-medium text-[#565758] dark:text-[#bdbdbd] md:text-sm">
              Email
            </h6>
            <p className="text-lg font-bold dark:text-[#bdbdbd]">
              {user.email}
            </p>
          </div>
          <div className="ml-3">
            <h6 className="text-xs font-medium text-[#565758] dark:text-[#bdbdbd] md:text-sm">
              Referral ID
            </h6>
            <p className="text-lg font-bold dark:text-[#bdbdbd]">
              {user.referral}
            </p>
          </div>
        </div>
      </div>

      <div className="overflow-hidden rounded-xl bg-brand-card shadow-lg">
        <div className="bg-[#FFFFFF] dark:bg-[#313b49] flex flex-col gap-8 px-6 py-4 ">
          <div className="ml-3">
            <h6 className="text-xs font-medium text-[#565758] dark:text-[#bdbdbd] md:text-sm">
              Paypal Payment Method
            </h6>
            <p className="text-lg font-bold dark:text-[#bdbdbd]">
              {user.paymentMethods.length > 0 ? (
                user.paymentMethods.map((pay, index) => (
                  <span key={index}>
                    {pay}
                    {index !== user.paymentMethods.length - 1 && ', '}
                  </span>
                ))
              ) : (
                <span>No paypal payment method added yet</span>
              )}
            </p>
            <h6 className="text-xs font-medium text-[#565758] dark:text-[#bdbdbd] md:text-sm">
              Total Amount
            </h6>
            <p className="text-lg font-bold dark:text-[#bdbdbd]">
              Completed amount: $
              {withdrawl?.userTotalWithdraw?.totalCompletedAmount || 0}
            </p>
            <p className="text-lg font-bold dark:text-[#bdbdbd]">
              Amount in process: $
              {withdrawl?.userTotalWithdraw?.totalRequestedAmount || 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
