import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

// import Input from '../Authentication/Inpute';

import adminServices from '../../services/admin.service';
import LoadingSpinner from '../ui/LoadingSpinner';
import { useSelector } from 'react-redux';
import Success from '../ui/Success';
import Error from '../ui/Error';

export default function EmailTemplates({ userId, email, onClose }) {
  const user = useSelector((state) => state.user);

  const [selectTemplate, setSelectTemplate] = React.useState('');

  const handleSend = async (values) => {
    const toastId = toast.loading(<LoadingSpinner message="Sending..." />);
    let response;
    try {
      if (selectTemplate === 'reminder') {
        response = await adminServices.reminderMail(userId, user.accessToken);
      } else if (selectTemplate === 'paymentFailed') {
        response = await adminServices.paymentFailedMail(
          userId,
          user.accessToken
        );
      } else if (selectTemplate === 'custom') {
        toast.dismiss(toastId);
        window.open('mailto:' + email);
        onClose();
        return;
      }

      if (response.success) {
        toast.update(toastId, {
          render: <Success message="Sent!" />,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        onClose();
      } else {
        toast.update(toastId, {
          render: (
            <Error
              message={response?.error.message || 'Something went wrong!'}
            />
          ),
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;

      toast.update(toastId, {
        render: <Error message={error.message || status} />,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <div>
        <ul className="mb-10 grid w-full grid-cols-1 gap-6 md:grid-cols-2">
          {templates.map((template) => (
            <li key={template.id}>
              <input
                type="radio"
                id={template.id}
                name="hosting"
                defaultValue={template.id}
                className="peer hidden"
                onChange={(e) => setSelectTemplate(e.target.value)}
                required
              />
              <label
                htmlFor={template.id}
                className="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-white p-5 text-gray-500 hover:bg-brand-bg hover:text-gray-600 peer-checked:border-brand-primary peer-checked:text-brand-primary dark:border-gray-700 dark:bg-brand-card dark:text-gray-400 dark:hover:bg-brand-bg dark:hover:text-gray-300 dark:peer-checked:text-brand-primary"
              >
                <div className="block">
                  <div className="w-full text-base font-semibold">
                    {template.name}
                  </div>
                  <div className="w-full text-sm">{template.desc}</div>
                </div>
                <svg
                  className="ml-3 h-5 w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </label>
            </li>
          ))}
        </ul>
        {selectTemplate && (
          <button
            type="submit"
            className="mt-4 rounded-md bg-brand-primary px-8 py-2 text-white"
            onClick={handleSend}
          >
            Send
          </button>
        )}
      </div>
    </div>
  );
}

const templates = [
  {
    id: 'reminder',
    name: 'Reminder-email',
    desc: 'Money will be cut',
  },
  {
    id: 'paymentFailed',
    name: 'Payment Failed',
    desc: 'Update payment method',
  },
  {
    id: 'custom',
    name: 'Custom',
    desc: 'Send a custom email',
  },
];
