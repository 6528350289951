import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Countdown from 'react-countdown';

// icons
import { FiCopy } from 'react-icons/fi';

// components
import DashboardWrapper from '../../components/dashboard/Wrapper';

function AffiliateUrls() {
  const user = useSelector((state) => state.user);

  return (
    <DashboardWrapper>
      <div className="mt-12">
        <h2 className="text-lg font-medium">Review Management</h2>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="w-full max-w-lg px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={
              'https://review.shadman-software-development.website?referral=' +
              user?.referral
            }
            disabled
          />
          <CopyToClipboard
            text={
              'https://review.shadman-software-development.website?referral=' +
              user?.referral
            }
            onCopy={() => toast.success('Copied to clipboard')}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
      </div>
      {/* <div className="mt-12">
        <h2 className="text-lg font-medium">Mortgage plugins</h2>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="w-full max-w-lg px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={
              "https://mortgage-calculator.savagesales.com/register?referral=" +
              user?.referral
            }
            disabled
          />
          <CopyToClipboard
            text={
              "https://mortgage-calculator.savagesales.com/register?referral=" +
              user?.referral
            }
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
      </div> */}
      {/* <div className="mt-6">
        <h2 className="text-lg font-medium">vCardsite</h2>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="w-full max-w-lg px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={
              "https://vcardsites.savagesales.com/register?referral=" +
              user?.referral
            }
            disabled
          />
          <CopyToClipboard
            text={
              "https://vcardsites.savagesales.com/register?referral=" +
              user?.referral
            }
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-lg font-medium">Tally</h2>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="w-full max-w-lg px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={
              "https://tallysites.savagesales.com/register?referral=" +
              user?.referral
            }
            disabled
          />
          <CopyToClipboard
            text={
              "https://tallysites.savagesales.com/register?referral=" +
              user?.referral
            }
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
      </div> */}
      {/* <div className="mt-6">
        <h2 className="text-lg font-medium">Coupons</h2>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={user?.referral + "_7daysfree"}
            disabled
          />
          <CopyToClipboard
            text={user?.referral + "_7daysfree"}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={user?.referral + "_14daysfree"}
            disabled
          />
          <CopyToClipboard
            text={user?.referral + "_14daysfree"}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value={user?.referral + "_30daysfree"}
            disabled
          />
          <CopyToClipboard
            text={user?.referral + "_30daysfree"}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
        </div>
        <div className="mt-2 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-4">
          <input
            className="px-3 py-2 text-sm border-[1px] bg-transparent border-[#343c45] dark:border-[#cfcfd3]"
            type="text"
            value="custom123"
            disabled
          />
          <CopyToClipboard
            text="custom123"
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <button className="w-fit px-6 py-2 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
              <span>Copy</span> <FiCopy />
            </button>
          </CopyToClipboard>
          <Countdown
            date={Date.now() + 100000000}
            renderer={renderer}
            zeroPadTime={2}
          />
        </div>
      </div> */}
    </DashboardWrapper>
  );
}

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span className="text-red-500">Expired</span>;
  } else {
    // Render a countdown
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};

export default AffiliateUrls;
