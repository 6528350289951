import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// services
import dashboardServices from '../../services/dashboard.service';
import adminService from '../../services/admin.service';
import { newNotification } from '../../utils/reducers/adminSlice';

import useSocket from '../../context/Socket';

// layouts
import AdminAside from './AdminAside';
import Footer from '../../layouts/Footer';

// components
import Loader from '../Loader';

function SuperAdminDashboardWrapper({ children }) {
  const socket = useSocket();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const dashboardData = useSelector((state) => state.dashboard);
  const withdrawl = useSelector((state) => state.withdrawal);

  useEffect(() => {
    if (!user) navigate('/', { replace: true });
    else if (!dashboardData || dashboardData === null) {
      (async () => {
        try {
          const response = await dispatch(
            dashboardServices.fetchData(user._id, user.token)
          );

          if (!response.success) {
            navigate('/', { replace: true });
          }
        } catch (err) {
          navigate('/', { replace: true });
        }
      })();
    }
    // if (user.accountType === 'Admin') {
    //   socket.on('notification', (data) => {
    //     dispatch(newNotification(data));
    //   });
    // }
  }, [user]);

  useEffect(() => {
    if (user.accountType !== 'Admin') {
      navigate('/', { replace: true });
    }
    (async () => {
      try {
        const response = await dispatch(adminService.getDashboard(user.token));

        if (!response.success) {
          console.log(response.error.message);
        }
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
        console.log(error || status);
      }
    })();

    if (user.accountType === 'Admin') {
      socket.on('notification', (data) => {
        dispatch(newNotification(data));
      });
    }
  }, []);

  if (dashboardData === null || !dashboardData || !withdrawl) {
    return <Loader />;
  } else if (!user) return;

  return (
    <div className="relative min-h-screen p-4 pb-36 lg:ml-72">
      <AdminAside />

      <main className="text-[#161C24] dark:text-[#cfcfd3]">
        <h1 className="text-3xl font-medium">
          Welcome, {user.firstName + ' ' + user.lastName}
        </h1>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default SuperAdminDashboardWrapper;
