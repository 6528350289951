import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';

import authServices from './../../services/auth.service';
import { addBankInfo } from './../../utils/reducers/bankSlice';

import LoadingSpinner from '../ui/LoadingSpinner';
import Error from '../ui/Error';
import Success from '../ui/Success';
import Input from './Input';

const initialValues = {
  beneficiaryBank: {
    bankName: '',
    payee: '',
    accountNumber: '',
    routingCode: '',
    branchAddress: {
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
    },
  },
  beneficiaryDetails: {
    address: {
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
      country: '',
    },
    phoneNumber: {
      countryDialingCode: '',
      number: '',
    },
    dateOfBirth: {
      day: '',
      month: '',
      year: '',
    },
    nationality: '',
  },
};

export default function BankInfoForm({ open, onClose }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [state, setState] = useState(initialValues);

  const handleSubmit = async (values) => {
    // console.log('form values: ', values);
    try {
      const response = await dispatch(
        authServices.addBankInfos(user._id, user.token, values)
      );
      if (response.success) {
        toast.success(response.status);
        onClose();
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status);
    }
  };

  // const formik = useFormik({
  //   initialValues,
  //   handleSubmit,
  // });

  const handleChange = (event) => {
    const { name, value } = event.target;

    const fieldNames = name.split('.');
    let newState = { ...state };

    fieldNames.reduce((nestedState, fieldName, index) => {
      if (index === fieldNames.length - 1) {
        if (
          fieldName === 'day' ||
          fieldName === 'month' ||
          fieldName === 'year'
        ) {
          nestedState.beneficiaryDetails.dateOfBirth = {
            ...nestedState.beneficiaryDetails.dateOfBirth,
            [fieldName]: value,
          };
        } else {
          nestedState[fieldName] = value;
        }
      } else {
        nestedState[fieldName] = nestedState[fieldName] || {};
      }
      return nestedState[fieldName];
    }, newState);

    setState(newState);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <div className="px-10 py-10 text-[#161C24] dark:text-[#cfcfd3] bg-[#FFFFFF] dark:bg-[#212B36]">
        <Formik
          enableReinitialize
          initialValues={state}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <Form>
              <h2 className="text-xl font-bold pb-4">
                Beneficiary Bank Details
              </h2>
              <Input
                type="text"
                name="beneficiaryBank.bankName"
                placeholder="Enter your bank name"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="bankName"
                label="Bank Name"
                required
              />
              <Input
                type="text"
                name="beneficiaryBank.payee"
                placeholder="Account Holder Name"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="holderName"
                label="Your Name"
                required
              />
              <Input
                type="text"
                name="beneficiaryBank.accountNumber"
                placeholder="0000 123 456 789"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="accNumber"
                label="Account Number"
                required
              />
              <Input
                type="text"
                name="beneficiaryBank.routingCode"
                placeholder="Bank Code"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="routingCode"
                label="Routing Code"
                required
              />
              <Input
                type="text"
                name="beneficiaryBank.branchAddress.line1"
                placeholder="House number/name and street name"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="branchAddress"
                label="Branch Address Line 1"
                required
              />
              <Input
                type="text"
                name="beneficiaryBank.branchAddress.line2"
                placeholder="Additional Address information"
                htmlFor="branchAddress2"
                label="Branch Address Line 2"
                onBlur={form.handleBlur}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="beneficiaryBank.branchAddress.city"
                placeholder="Town/City"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="branchCity"
                label="Branch City"
                required
              />
              <Input
                type="text"
                name="beneficiaryBank.branchAddress.postalCode"
                placeholder="Postal Code"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="postalCode"
                label="Branch Postal/Zip Code"
                required
              />
              <h2 className="text-xl font-bold pb-4">Beneficiary Details</h2>
              <Input
                type="text"
                name="beneficiaryDetails.address.line1"
                placeholder="House number/name and street name"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="bAddress1"
                label="Address Line 1"
                required
              />
              <Input
                type="text"
                name="beneficiaryDetails.address.line2"
                placeholder="Additonal address information"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="bAddress2"
                label="Address Line 2"
              />
              <Input
                type="text"
                name="beneficiaryDetails.address.city"
                placeholder="Town/City"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="bCity"
                label="City"
                required
              />
              <Input
                type="text"
                name="beneficiaryDetails.address.postalCode"
                placeholder="Postal Code"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="bPostalCode"
                label="Postal/Zip Code"
                required
              />
              <Input
                type="text"
                name="beneficiaryDetails.address.country"
                placeholder="Country Name"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="country"
                label="Country"
                required
              />

              <Input
                type="text"
                name="beneficiaryDetails.phoneNumber.countryDialingCode"
                placeholder="+1"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="dailCode"
                label="Phone Number Country Dialling Code"
                required
              />

              <Input
                type="text"
                name="beneficiaryDetails.phoneNumber.number"
                placeholder="xxx-xxxxxxxx"
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="phoneNumber"
                label="Phone Number"
                required
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  name="beneficiaryDetails.dateOfBirth"
                  value={form.values.beneficiaryDetails.dateOfBirth}
                  onBlur={form.handleBlur}
                  onChange={(newValue) => {
                    const parsedDate = new Date(newValue);
                    const formattedDate = {
                      day: parsedDate.getDate(),
                      month: parsedDate.getMonth() + 1,
                      year: parsedDate.getFullYear(),
                    };
                    form.setFieldValue(
                      'beneficiaryDetails.dateOfBirth',
                      formattedDate
                    );
                  }}
                />
              </LocalizationProvider>

              <Input
                type="text"
                name="nationality"
                placeholder=""
                onBlur={form.handleBlur}
                onChange={handleChange}
                htmlFor="natonality"
                label="Nationality"
              />

              <div className="flex flex-col-reverse lg:flex-row gap-5">
                <button
                  type="submit"
                  disabled={form.isSubmitting}
                  className="max-w-[120px] w-full px-4 py-2 mt-4 mx-auto dark:bg-[#161C24] bg-[#cfcfd3] rounded-md transform hover:scale-105 transition-all"
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}
