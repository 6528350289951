import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: null,
  reducers: {
    setAdminData: (state, action) => {
      const { payload } = action;
      return payload;
    },
    removeUser: (state, action) => {
      const { payload } = action;
      let tmpList = state.users._list.filter((dt) => dt._id !== payload._id);

      return { ...state, users: { ...state.users, _list: tmpList } };
    },
    readNotification: (state, action) => {
      const { payload } = action;
      let tmptList = state.notifications._list.map((dt) => {
        if (dt._id === payload._id) {
          return payload;
        } else return dt;
      });
      let tmptUnread = state.notifications.unread_list.filter(
        (dt) => dt._id !== payload._id
      );

      return {
        ...state,
        notifications: {
          ...state.notifications,
          unread_list: tmptUnread,
          _count: state.notifications._count + 1,
          unread_count: tmptUnread.length,
          _list: tmptList,
        },
      };
    },
    newNotification: (state, action) => {
      const { payload } = action;
      let findN = state.notifications._list.find(
        (dt) => dt._id === payload._id
      );
      if (findN) return state;
      return {
        ...state,
        notifications: {
          unread_list: [payload, ...state.notifications.unread_list],
          _count: state.notifications._count + 1,
          unread_count: state.notifications.unread_count + 1,
          _list: [payload, ...state.notifications._list],
        },
      };
    },
  },
});

export const { setAdminData, removeUser, readNotification, newNotification } =
  adminSlice.actions;
export default adminSlice.reducer;
