import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import authServices from '../../services/auth.service';

// icons
import { FiCopy } from 'react-icons/fi';

// components
import DashboardWrapper from '../../components/dashboard/Wrapper';
import SuperAdminDashboardWrapper from '../../components/SuperAdmin/AdminDashboardWrapper';

function Settings() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    setFormValues({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    let data = formValues;
    if (!data.firstName || !data.lastName) {
      toast.error("You can't keep empty fields");
      return;
    }
    try {
      let response = await dispatch(authServices.updateProfile(user._id, data));

      if (response.success) {
        toast.success(response.status);
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <SuperAdminDashboardWrapper>
      <div className="mt-12">
        <h2 className="text-lg font-medium">Settings</h2>

        <form className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4" action="">
          <div className="flex flex-col space-y-3">
            <label htmlFor="firstName" className="text-lg">
              First name
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              className="px-2 py-2 rounded-md bg-[#FFFFFF] dark:bg-[#212B36] outline-none"
              value={formValues.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label htmlFor="lastName" className="text-lg">
              Last name
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              className="px-2 py-2 rounded-md bg-[#FFFFFF] dark:bg-[#212B36] outline-none"
              value={formValues.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label htmlFor="email" className="text-lg">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="px-2 py-2 rounded-md bg-[#FFFFFF] dark:bg-[#212B36] outline-none"
              value={user.email}
              disabled
            />
          </div>
        </form>

        <div className="mt-6">
          <button
            onClick={handleSave}
            className="max-w-[200px] w-full px-4 py-2 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md transform hover:scale-105 transition-all"
          >
            Save Changes
          </button>
        </div>

        <div className="mt-8 flex flex-col space-y-3">
          <label className="text-lg">Password</label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              className="px-2 py-2 select-none rounded-md bg-[#FFFFFF] dark:bg-[#212B36] outline-none"
              value="*** *** ***"
            />
          </div>
        </div>

        <div className="mt-8 flex flex-col space-y-3">
          <label className="text-lg">Referral Code</label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              className="px-2 py-2 select-none rounded-md bg-[#FFFFFF] dark:bg-[#212B36] outline-none"
              value={user.referral}
            />
            <CopyToClipboard
              text={
                'https://get-reviews.savagesales.com/register?referral=' +
                user?.referral
              }
              onCopy={() => toast.success('Copied to clipboard')}
            >
              <button className="w-fit px-6 py-1 flex items-center dark:hover:text-black hover:bg-[#dbffdb] border-[1px] border-[#343c45] dark:border-[#cccccc] hover:border-[#00C642] rounded-md space-x-2 transition-all duration-300 ease-in-out">
                <span>Copy</span> <FiCopy />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </SuperAdminDashboardWrapper>
  );
}

export default Settings;
