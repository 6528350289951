// modules
import axios from '../utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { removeUser, setUser, updateUser } from '../utils/reducers/userSlice';

import {
  setBankInfos,
  addBankInfo,
  removeBankInfo,
  updateBankInfo,
  setWithdrawInfo,
} from '../utils/reducers/bankSlice';
import { updateDashboardData } from '../utils/reducers/dashboardSlice';

const services = {
  getUser: () => (dispatch) => {
    return axios({
      url: '/api/users/getUser',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(setUser(resData.data));
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  register: (data) => {
    return axios({
      url: '/api/users/register',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  login:
    ({ email, password, session }) =>
    (dispatch) => {
      return axios({
        url: '/api/users/login',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email,
          password,
          session,
        },
      }).then((response) => {
        const resData = response.data;

        if (resData.success) {
          dispatch(setUser(resData.data));
          return {
            success: true,
            status: 'Logged in!',
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },
  loginMortgage:
    ({ email, password, session }) =>
    (dispatch) => {
      return axios({
        url: '/api/users/login/mortgage',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email,
          password,
          session,
        },
      }).then((response) => {
        const resData = response.data;

        if (resData.success) {
          dispatch(setUser(resData.data));
          return {
            success: true,
            status: 'Logged in!',
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },
  logout: () => (dispatch) => {
    return axios({
      url: '/api/users/logout',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(removeUser());
        return {
          success: true,
          status: 'Logged out!',
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  changePassword: (userId, token, oldPassword, newPassword) => {
    return axios({
      url: '/api/users/' + userId + '/change_password',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        oldPassword,
        newPassword,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },
  resetPassword: (newPassword, token) => {
    return axios({
      url: '/api/users/reset_password',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        newPassword,
        token,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },
  // forgot password
  forgotPassword: (email) => {
    return axios({
      url: '/api/users/reset/password',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  verifyResetToken: (token) => {
    return axios({
      url: '/api/users/verify/reset/password/' + token,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  update: (userId, data) => (dispatch) => {
    return axios({
      url: '/api/users/' + userId + '/update',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  addPaymentMethod: (userId, data) => (dispatch) => {
    return axios({
      url: '/api/users/' + userId + '/add/payment_method',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  removePaymentMethod: (userId, data) => (dispatch) => {
    return axios({
      url: '/api/users/' + userId + '/remove/payment_method',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  updateProfile: (userId, data) => (dispatch) => {
    return axios({
      url: '/api/users/' + userId + '/update/profile',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  withdraw: (userId, data) => (dispatch) => {
    return axios({
      url: '/api/users/' + userId + '/withdraw',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateDashboardData(resData.data));
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  getBankInfos: (userId) => (dispatch) => {
    return axios({
      url: '/api/bankinfo/' + userId,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
    }).then((response) => {
      const resData = response.data;
      console.log('bankInfos:', resData);
      if (resData.success) {
        dispatch(setBankInfos(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  addBankInfos: (userId, token, data) => (dispatch) => {
    return axios({
      url: '/api/bankinfo/' + userId + '/add',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        // console.log('ResData Console', resData);
        dispatch(addBankInfo(resData.data));
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  deleteBankInfo: (bankId) => (dispatch) => {
    return axios({
      url: '/api/bankinfo/' + bankId + '/delete',
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + token,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(removeBankInfo(resData.data)); // Dispatch the action here
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },

  getWithdrawInfos: (userId) => (dispatch) => {
    return axios({
      url: '/api/bankinfo/withdrawal-history/' + userId,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: "Bearer " + token,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(setWithdrawInfo(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
          error: resData.error,
        };
      }
    });
  },
};

export default services;
