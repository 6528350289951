import React from 'react';
import { Link } from 'react-router-dom';

export function AdminCard({ CardIcon, title, value, link = false }) {
  return (
    <div className="bg-[#FFFFFF] dark:bg-[#212B36] shadow-lg rounded-xl overflow-hidden">
      <div className="px-6 py-4 flex bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="mt-3 text-xl md:text-2xl text-[#565758] dark:text-[#bdbdbd]">
          <CardIcon />
        </div>
        <div className="ml-3">
          <h6 className="text-xs md:text-sm text-[#565758] dark:text-[#bdbdbd] font-medium">
            {' '}
            {title}{' '}
          </h6>
          <p className="text-2xl md:text-3xl dark:text-[#bdbdbd] font-bold">
            {value}
          </p>
        </div>
      </div>
      {/* {link && (
        <div className="px-6 py-2 bg-[#ececec] dark:bg-[#1a222a]">
          <Link
            to={link}
            className="text-[#AD1418] text-xs md:text-sm underline"
          >
            View all
          </Link>
        </div>
      )} */}
    </div>
  );
}
