import React from 'react';
// import Header from '../../components/Dashboard/Header';
import InfiniteNotification from '../../components/SuperAdmin/InfiniteNotification';
import { Outlet, useParams } from 'react-router-dom';
import AdminDashboardWrapper from '../../components/SuperAdmin/AdminDashboardWrapper';

export default function Notification() {
  const { notificationId } = useParams();
  return (
    <>
      <AdminDashboardWrapper>
        <h4 className="text-bse py-2 text-xl font-medium text-black dark:text-white">
          All Notification
        </h4>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 md:col-span-1 bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg px-4">
            <InfiniteNotification />
          </div>

          {notificationId ? (
            <Outlet />
          ) : (
            <div className="hidden md:block col-span-2 bg-brand-card rounded-lg p-4">
              <div className="w-full h-full grid place-items-center">
                <div>
                  {/* <img
                    src="/images/Push-Notification-color.svg"
                    alt="notification svg"
                    className="w-48"
                  /> */}
                  <h3 className="text-center text-base text-neutral-800 dark:text-neutral-300 mt-4">
                    Select a notification to view details{' '}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </AdminDashboardWrapper>
    </>
  );
}
