// modules
import { createTheme } from '@mui/material/styles';

export const tableDarkTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#212B36',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#cfcfd3',
          backgroundColor: '#212B36',
          borderColor: '#343c45',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#cfcfd3',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: '#cfcfd3',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#cfcfd3 !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#cfcfd3 !important',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#cfcfd3 !important',
        },
      },
    },

    MuiSelectOutlined: {
      styleOverrides: {
        root: {
          color: '#cfcfd3 !important',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#cfcfd3 !important',
        },
      },
    },
  },
});

export const tableLightTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#161C24',
          backgroundColor: '#FFFFFF',
          borderColor: '#e5e7eb',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#161C24',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: '#161C24',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#161C24 !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#161C24 !important',
        },
      },
    },
    MuiSelectSelect: {
      styleOverrides: {
        root: {
          color: '#161C24 !important',
        },
      },
    },

    MuiSelectOutlined: {
      styleOverrides: {
        root: {
          color: '#161C24 !important',
        },
      },
    },
  },
});
