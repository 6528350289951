import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsFacebook, BsGoogle } from 'react-icons/bs';

// services
import authServices from '../../services/auth.service';

// layouts
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

function Register() {
  const navigate = useNavigate();

  const [isRegistering, setIsRegistering] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateFields = (values) => {
    let validated = false;

    if (values.password !== values.confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Password didn't match with confirm password!",
        confirmPassword: "Confirm Password didn't match with password!",
      }));
      validated = false;
    } else {
      setFormErrors((prev) => ({
        ...prev,
        password: null,
        confirmPassword: null,
      }));
      validated = true;
    }

    return validated;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const isValidated = validateFields(formValues);

    if (isValidated) {
      setIsRegistering(true);
      try {
        let data = formValues;

        const response = await authServices.register(data);

        if (response.success) {
          toast.success(response.status);
          navigate('/', { replace: true });
        } else if (!response.accountApproval) {
          toast.success(response.status);
          navigate('/', { replace: true });
        } else {
          setIsRegistering(false);
          toast.error(response.status);
        }
      } catch (err) {
        setIsRegistering(false);
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      }
    }
  };

  return (
    <div className="pb-32">
      <Header />
      <div className="sm:container sm:mx-auto sm:max-w-lg pt-12 sm:pt-24 min-h-[calc(100vh-142px)]">
        {' '}
        {/*min-height:calc(100vh - 135px) in upper div*/}
        <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
          <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
            Register
          </div>
          {/*  */}
          {/* Reg with social */}
          <div className="RegWLink p-5">
            <h5 className="text-[#161C24] dark:text-white text-center">
              Register with one of the following:
            </h5>
            <div className="allLinks flex justify-center mt-3">
              <a href="/api/users/auth/facebook">
                <BsFacebook className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
              </a>
              <a href="/api/users/auth/google">
                <BsGoogle className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
              </a>
            </div>
          </div>

          {/* orr */}
          <div
            className="text-center  relative before:content-[''] before:block max-[415px]:before:w-[130px]  before:w-[200px] before:h-[2px] before:absolute before:left-0 before:top-1/2  before:bg-[#424250] before:ml-7 
     after:content-[''] after:block  max-[415px]:after:w-[130px] after:w-[200px] after:h-[2px] after:absolute after:right-0 after:top-1/2  after:bg-[#424250] after:mr-7"
          >
            <span className="text-white bg-[#33333D]">OR</span>
          </div>

          <div className="form pt-4">
            <form
              className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
              onSubmit={handleRegister}
            >
              <div>
                {/* <label htmlFor="email">E-Mail Address</label> */}
                <input
                  type="text"
                  name="firstName"
                  placeholder="Frist Name"
                  value={formValues.firstName}
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="text"
                  name="lastName"
                  value={formValues.lastName}
                  placeholder="Last Name"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>

              <div className="email">
                {/* <label htmlFor="email">E-Mail Address</label> */}
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  placeholder="E-Mail Address"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>

              <div className="flex flex-col space-y-1">
                {/* <label htmlFor="email">E-Mail Address</label> */}
                <input
                  type="password"
                  name="password"
                  value={formValues.password}
                  placeholder="Password"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
                {formErrors.password && (
                  <span>
                    {' '}
                    <small className="text-red-600">
                      {' '}
                      {formErrors.password}{' '}
                    </small>{' '}
                  </span>
                )}
              </div>

              <div className="flex flex-col space-y-1">
                {/* <label htmlFor="email">E-Mail Address</label> */}
                <input
                  type="password"
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  placeholder="Confirm Password"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />

                {formErrors.confirmPassword && (
                  <span>
                    {' '}
                    <small className="text-red-600">
                      {' '}
                      {formErrors.confirmPassword}{' '}
                    </small>{' '}
                  </span>
                )}
              </div>

              {/* buttton others */}
              <div className="flex flex-wrap">
                <button
                  type="submit"
                  className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                  disabled={isRegistering}
                >
                  Register
                </button>{' '}
                <p className="w-full text-xs text-center my-6 sm:text-sm sm:my-8 text-[#161C24] dark:text-[#CFCFD3] ">
                  Already have an account?
                  <Link
                    to="/"
                    className="text-[#AD1418] no-underline hover:underline ml-1"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
