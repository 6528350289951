import * as React from 'react';
import { Avatar, Dialog } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';

import { tableDarkTheme, tableLightTheme } from '../../utils/themes.mui';

import { useSearchParams } from 'react-router-dom';
// import SwitchButton from "../ui/SwitchButton";
import EmailTemplates from './EmailTemplates';
import UserFilter from './UserFilter';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import adminServices from '../../services/admin.service';
import LoadingSpinner from '../ui/LoadingSpinner';
import Success from '../ui/Success';
import Error from '../ui/Error';
import Details from './Details';
// import Details from './Details';
// import CustomSubscription from './CustomSubscription';

export default function UsersTable({
  rows,
  homepage,
  perRows = 6,
  setStateData,
}) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const user = useSelector((state) => state.user);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(perRows);
  const [searchParams] = useSearchParams();
  const [users, setUsers] = React.useState([]);
  // const accountTypes = searchParams.get('accountType');
  const userSearch = searchParams.get('users');
  const members = searchParams.get('members');
  // const subscription = searchParams.get('subscription');
  // const accountType = searchParams.get('Basic');
  const search = searchParams.get('search');
  const [open, setOpen] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openCustomSub, setCustomSub] = React.useState(false);

  const [selectedUser, setSelectedUser] = React.useState(null);

  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // React.useEffect(() => {
  //   if (accountTypes && accountTypes.toLowerCase() === 'all') {
  //     setUsers(rows);
  //     return;
  //   }

  //   const filterFunction = (user) => {
  //     if (typeof user.accountTypes !== 'string') {
  //       return false;
  //     }

  //     const lowerAccountTypes = user.accountTypes.toLowerCase();

  //     if (
  //       (basic || members) &&
  //       lowerAccountTypes.includes(accountTypes.toLowerCase())
  //     ) {
  //       return true;
  //     }

  //     if (search) {
  //       return Object.values(user).some((value) => {
  //         if (typeof value === 'string') {
  //           return value.replace(/\s/g, '').toLowerCase().includes(search);
  //         }
  //         return false;
  //       });
  //     }

  //     return false;
  //   };

  //   const filteredUsers = rows.filter(filterFunction);
  //   setUsers(filteredUsers);
  // }, [accountTypes, basic, members, search, rows]);

  React.useEffect(() => {
    if (userSearch && userSearch.toLowerCase() === 'all') {
      setUsers(rows);
      return;
    }
    if (userSearch) {
      const filteredUser = rows.filter((user) =>
        user.accountType.toLowerCase().includes(userSearch.toLowerCase())
      );
      setUsers(filteredUser);
    } else if (search) {
      const filteredUser = rows.filter((user) =>
        Object.values(user).find((value) => {
          if (typeof value === 'string') {
            return value.replace(/\s/g, '').toLowerCase().includes(search);
          }
          return false;
        })
      );

      setUsers(filteredUser);
    } else {
      setUsers(rows);
    }
  }, [userSearch, search, rows]);

  const handleClickOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleClickDetails = (user) => {
    setSelectedUser(user);
    setOpenDetails(true);
  };
  const handleCloserDetails = () => {
    setOpenDetails(false);
    setSelectedUser(null);
  };

  const handleSelectIndividual = (id) => {
    if (selectedUsers.findIndex((dt) => dt._id === id) > -1) {
      let tmpt = selectedUsers.filter((dt) => dt._id !== id);
      setSelectedUsers(tmpt);
    } else {
      let tmpt = rows.find((dt) => dt._id === id);
      setSelectedUsers((prev) => [...prev, tmpt]);
    }
  };

  const handleSelectAll = () => {
    if (rows.length === selectedUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(rows);
    }
  };

  const handleDeleteUserMultiple = async () => {
    if (selectedUsers.length > 0) {
      await Promise.all(
        selectedUsers.map(async (dt) => {
          await handleDeleteUser(dt._id);
        })
      );

      setSelectedUsers([]);
    } else {
      toast.error('No user selected');
    }
  };

  const handleApproveUser = async (userId) => {
    try {
      const response = await dispatch(
        adminServices.approvalUpdate(userId, user.token)
      );

      if (response.success) {
        const updatedUsers = users.map((user) => {
          if (user._id === userId) {
            return { ...user, accountApproval: true };
          }
          return user;
        });

        // Update the UI immediately
        setUsers(updatedUsers);
        toast.success(response.status);
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      let errors = err.response.data.error;
      let status = err.response.data.status;
      setUsers(users);
      toast.error(errors.message || status);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await dispatch(
        adminServices.deleteUser(userId, user.token)
      );

      if (response.success) {
        toast.success(response.status);
      } else {
        toast.error('Something went wrong, pleas try again');
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status);
    }
  };

  return (
    <ThemeProvider theme={theme === 'dark' ? tableDarkTheme : tableLightTheme}>
      <div className="col-span-3 overflow-hidden rounded-md bg-brand-card">
        <div className="mb-2 flex w-full flex-wrap items-center justify-between border-b  border-gray-300 px-4 py-4 dark:border-gray-700">
          <h4 className="text-bse py-2 text-xl font-medium text-black dark:text-white">
            Users Table
          </h4>
          <div className="flex flex-wrap items-center gap-4">
            <UserFilter />

            {selectedUsers.length > 0 && (
              <>
                {' '}
                <button
                  className="rounded-md bg-[#161C24] text-[#cfcfd3] px-8 py-2 dark:text-[#161C24] dark:bg-[#cfcfd3]"
                  onClick={handleDeleteUserMultiple}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>

        <TableContainer className="px-4">
          <Table stickyHeader aria-label="sticky table" className="">
            <thead className="bg-neutral-100 text-brand-heading dark:bg-[#212B36]">
              <TableRow>
                <TableCell
                  align="center"
                  style={{ minWidth: 80, fontSize: 13 }}
                >
                  <Checkbox
                    checked={
                      rows.length === selectedUsers.length &&
                      selectedUsers.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {!homepage && (
                  <TableCell
                    style={{
                      minWidth: 150,
                      fontWeight: 600,
                      fontSize: 13,
                    }}
                  >
                    Profile picture
                  </TableCell>
                )}

                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: 200, fontWeight: 600, fontSize: 13 }}
                    className={`bg-neutral-100 dark:bg-[#212B36] ${
                      column.id === 'firstName'
                        ? 'left-0 z-10 md:sticky'
                        : column.id === 'email'
                        ? 'left-[200px] z-10 md:sticky'
                        : ''
                    }`}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell
                  align="center"
                  style={{ minWidth: 200, fontWeight: 600, fontSize: 13 }}
                >
                  Send email
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: 200, fontWeight: 600, fontSize: 13 }}
                >
                  Details
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: 200, fontWeight: 600, fontSize: 13 }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </thead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell
                      align="center"
                      style={{ minWidth: 80, fontSize: 13 }}
                    >
                      <Checkbox
                        checked={
                          selectedUsers.findIndex((dt) => dt._id === row._id) >
                          -1
                        }
                        onChange={() => handleSelectIndividual(row._id)}
                      />
                    </TableCell>
                    {!homepage && (
                      <TableCell align="center">
                        <Avatar
                          src={row.profileImage}
                          alt={row.firstName}
                          sx={{
                            height: '2.5rem',
                            width: '2.5rem',
                            objectFit: 'cover',
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'subscription') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: 13 }}
                          >
                            {value.status}
                          </TableCell>
                        );
                      } else if (column.id === 'trialEnd') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: 13 }}
                          >
                            {typeof value === 'boolean'
                              ? value
                                ? 'Ended'
                                : 'Not Ended'
                              : value}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ fontSize: 13 }}
                          className={`bg-neutral-100 dark:bg-[#212B36] dark:text-[#cfcfd3] ${
                            column.id === 'firstName'
                              ? 'left-0 z-10 md:sticky'
                              : column.id === 'email'
                              ? 'left-[200px] z-10 md:sticky'
                              : ''
                          }`}
                        >
                          {typeof value === 'boolean'
                            ? value
                              ? 'Yes'
                              : 'No'
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell align="center">
                      <button
                        className="rounded-md bg-brand-primary px-8 py-2 dark:text-[#cfcfd3]"
                        onClick={() => handleClickOpen(row)}
                      >
                        Send email
                      </button>
                    </TableCell>
                    <TableCell align="center">
                      {row === null ? (
                        '---'
                      ) : (
                        <button
                          className="rounded-md bg-brand-primary px-8 py-2 dark:text-[#cfcfd3]"
                          onClick={() => handleClickDetails(row)}
                        >
                          View
                        </button>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className="flex flex-col items-center space-y-4"
                    >
                      <button
                        className="rounded-md bg-brand-primary px-8 py-2 dark:text-[#cfcfd3]"
                        onClick={() => handleDeleteUser(row._id)}
                      >
                        Delete
                      </button>
                      {!row.accountApproval ? (
                        <button
                          className="rounded-md bg-brand-primary px-8 py-2 dark:text-[#cfcfd3]"
                          onClick={() => handleApproveUser(row._id)}
                        >
                          Approve
                        </button>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    {/* <TableCell
                    align="center"
                    className="flex flex-col items-center space-y-4"
                  >
                    {!row.accountApproval ? (
                      <button
                        className="rounded-md bg-brand-primary px-8 py-2 dark:text-[#cfcfd3]"
                        onClick={() => handleApproveUser(row._id)}
                      >
                        Approve
                      </button>
                    ) : (
                      ''
                    )}
                  </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="dark:text-[#cfcfd3]"
          rowsPerPageOptions={[6]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <div className=" space-y-8 bg-brand-card px-10 py-8">
            <h1 className="text-center text-2xl font-bold text-brand-heading">
              Choose a template
            </h1>
            <div className="py-4">
              <EmailTemplates
                userId={selectedUser && selectedUser._id}
                email={selectedUser && selectedUser.email}
                onClose={handleClose}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          open={openDetails}
          onClose={handleCloserDetails}
          maxWidth="sm"
          fullWidth
        >
          <div className="space-y-8 bg-[#FFFFFF] dark:bg-[#212B36] px-10 py-8">
            <Details user={selectedUser} />
          </div>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}

const columns = [
  { id: 'firstName', label: 'Full Name', align: 'center' },
  { id: 'email', label: 'Email', align: 'center' },
  {
    id: 'verified',
    label: 'Verify status',
    align: 'center',
  },
];
